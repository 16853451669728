import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { identity, noop, toLower } from "lodash";

export default function DeletAccountDialog({
  T = identity,
  open,
  onCancelAndClose = noop,
  onDoneAndClose = noop,
  error,
}) {
  const [
    deleteForeverValidationText,
    setDeleteForeverValidationText,
  ] = useState("");
  const [validationError, setValidationError] = useState(null);
  const handleClose = () => {
    onCancelAndClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (toLower(deleteForeverValidationText) === "delete forever") {
      onDoneAndClose();
    } else {
      setValidationError(true);
    }
  };

  const handleChangeValidationText = (e) => {
    setDeleteForeverValidationText(e.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">
          {T("Delete your {{business_name}} loyalty account forever?")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="inherit">
            {T(`By deleting your account, all of your online ordering and rewards history - including points, store credit or any other benefits received by {{business_name}} online - will be detached from your personal details.
You will be unsubscribed from all of our online and marketing services, and be removed from our loyalty program.
Your transactions and actions will be kept in the system for reporting, accounting and billing purposes only. {{business_name}} would not be able to search your details for marketing purposes.
After the account is deleted, you won't be able to sign up again or order as a guest using the account's phone number.
You can contact {{business_name}} in order to use the account's phone number in order to create a fresh account.`)}
          </DialogContentText>
          {error && (
            <DialogContentText id="alert-dialog-error" color="error">
              {T("Your account deletion failed, please try again.")}
            </DialogContentText>
          )}
          <Typography
            variant="subtitle1"
            color={validationError ? "error" : "primary"}
          >
            {T("Type 'Delete Forever'")}
          </Typography>
          <TextField
            variant="filled"
            required
            onChange={handleChangeValidationText}
            name="Delete Forever"
            value={deleteForeverValidationText}
            error={validationError}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            autoFocus
            variant="contained"
          >
            {T("Cancel")}
          </Button>
          <Button type="submit" color="secondary" variant="outlined">
            {T("Delete My Acount")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
