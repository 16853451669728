import React from "react";
import { connect } from "react-redux";
import withTranslation from "../hocs/withTranslation";
import get from "lodash/get";
import {
  logout,
  setProfileInRegularMode,
  registerPushNotifications,
  loadLoyaltyProfile,
  loadPaymentMethods,
  resetGiftCard,
  resetSubscription,
  rechargeCardReset,
  getExternalGiftCards,
  addExternalGiftCardToAccount,
  removeExternalGiftCardFromAccount,
  resetAddExternalGiftCardToAccount,
  deleteAccount,
  markShowedPushApprovalPopup
} from "../store/user/actions";
import { rechargeCard } from "../store/order/actions";
import {
  getCashbackDiscounts,
  getCurrentPaymentMethodDetails,
  getRelevantSubscriptionsForUser
} from "../store/selectors/index";
import AccountMenu from "../components/account-menu";
import { getAppStyles } from "../store/selectors/app-styles";

const MyAccountPage = (props) => <AccountMenu {...props} />;

const mapStateToProps = (state, props) => {
  const { user, order } = state;
  return {
    user,
    order,
    appStyles: getAppStyles(state, props),
    cashbackDiscounts: getCashbackDiscounts(state, props),
    paymentTypeDetails: getCurrentPaymentMethodDetails(state, props),
    subscriptionPolicies: getRelevantSubscriptionsForUser(state, props)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const {
    pageContext: { businessAppConfiguration = {}, business },
  } = props;
  const { hasCashback, prePaymentEnabled } = businessAppConfiguration;

  const currency = get(business, "openChargeCard.price.currency", 0);
  const chargeCardBasePrice = get(business, "openChargeCard.price");

  return {
    logout: () => dispatch(logout()),
    setProfileInRegularMode: () => dispatch(setProfileInRegularMode()),
    registerPushNotifications: (registrationInfo) =>
      dispatch(registerPushNotifications(registrationInfo)),
    loadLoyaltyProfile: (force) => dispatch(loadLoyaltyProfile(force)),
    loadPaymentMethods: (paymentTypeIdentifier) =>
      dispatch(loadPaymentMethods(paymentTypeIdentifier, currency)),
    rechargeCard: ({ customerDetails, cvv, creditCard, paymentTypeDetails }) =>
      dispatch(
        rechargeCard({
          customerDetails,
          cvv,
          paymentTypeDetails,
          currency,
          chargeCardBasePrice,
          creditCard,
        }),
      ),

    resetGiftCard: () => dispatch(resetGiftCard()),
    resetSubscription: () => dispatch(resetSubscription()),
    rechargeCardReset: () => dispatch(rechargeCardReset()),
    getExternalGiftCards: (branchId) =>
      dispatch(getExternalGiftCards(branchId)),
    addExternalGiftCardToAccount: ({ cardNumber, cardPIN }) =>
      dispatch(addExternalGiftCardToAccount({ cardNumber, cardPIN })),
    resetAddExternalGiftCardToAccount: () =>
      dispatch(resetAddExternalGiftCardToAccount()),
    removeExternalGiftCardFromAccount: ({ cardNumber }) =>
      dispatch(removeExternalGiftCardFromAccount({ cardNumber })),
    deleteAccount: () => dispatch(deleteAccount()),
    markShowedPushApprovalPopup: () => dispatch(markShowedPushApprovalPopup())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(MyAccountPage));
